import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class ToolbarTabsService {

    private _config = new Subject();
    configOb = this._config.asObservable();

    constructor() { }


    setConfig(data: any) {
        // console.log(data);
        this._config.next(data);
    }

}
