import { NgModule } from '@angular/core';

import { MomentPipe,DateToAgePipe } from './moment.pipe';
import { ArraySortPipe,SafePipe,SearchArrObjPipe,TextValuePipe,KeysFilterPipe } from './misc.pipe';

@NgModule({
    declarations: [
        MomentPipe,
        DateToAgePipe,
        ArraySortPipe,
        SafePipe,
        SearchArrObjPipe,
        TextValuePipe,
        KeysFilterPipe
    ],
    imports     : [],
    exports     : [
        MomentPipe,
        DateToAgePipe,
        ArraySortPipe,
        SafePipe,
        SearchArrObjPipe,
        TextValuePipe,
        KeysFilterPipe
    ]
})
export class FusePipesModule
{
}
