import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';

@Injectable()
export class InscriptionService {

    private _children = new Subject();
    childenOb = this._children.asObservable();

    private _changeInfo = new Subject();
    changeInfoOb = this._changeInfo.asObservable();

    constructor() { }

    setChildren(data:any){
        this._children.next(data);
    }

    setInfoParents(data:boolean){
        this._changeInfo.next(data);
    }

}