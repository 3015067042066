import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';

import { fuseConfig } from '@config/helpers/fuse-config';

import { CedMainModule } from './main/main.module';
import { AppComponent } from './app.component';


/**
 * @CONFIG
 */
import { ConfigModule } from '@config/config.module';

 /*ROUTES*/
import { appRoutes } from '@config/routing/app.routing';

import { ToastrModule } from 'ngx-toastr';

import { CKEditorModule } from 'ngx-ckeditor';

import { NgxChartsModule } from '@swimlane/ngx-charts';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    TranslateModule.forRoot(),
    // Fuse Main and Shared modules
    FuseModule.forRoot(fuseConfig),
    NgbModule.forRoot(),
    FuseSharedModule,
    CedMainModule,
    ConfigModule,
    ToastrModule.forRoot(),
    CKEditorModule,
    NgxChartsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
