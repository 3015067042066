import { Injectable } from '@angular/core';

import { TOKEN_NAME } from '@config/helpers/const';

import { UserService } from './user.services';

import { Router } from '@angular/router';

import * as jwt_decode from 'jwt-decode';

@Injectable()
export class AuthService {

  constructor(private _user:UserService,public router: Router) { }

  removeToken() {
    localStorage.removeItem(TOKEN_NAME);
  }

  getToken(): string {
    return localStorage.getItem(TOKEN_NAME);
  }

  setToken(token: string): void {
    localStorage.setItem(TOKEN_NAME, token);
  }

  getTokenDecode(token?: string) {
    if (!token) token = this.getToken();
    if (!token) return false;
    return jwt_decode(token);
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);

    if (decoded.exp === undefined) return null;

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) token = this.getToken();
    if (!token) return true;

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  }

  logout(){
    this.removeToken();
    this._user.removeUser();
  }

}