import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'keys'})
export class KeysPipe implements PipeTransform
{
    transform(value: any, args: string[]): any
    {
        const keys: any[] = [];
        for ( const key in value )
        {
            if ( value.hasOwnProperty(key) )
            {
                keys.push({
                    key  : key,
                    value: value[key]
                });
            }
        }
        if(!args){
            keys.sort(function (a, b) {
                if (a.value < b.value) { return -1; }
                if (a.value > b.value) { return 1; }
                return 0;
            });
        }
        

        return keys;
    }
}
