import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import * as moment from 'moment';

@Pipe({
    name: "sortobjectarray"
})
export class ArraySortPipe implements PipeTransform {
    transform(array: any[], field: string): any[] {
        if (!array) return [];
        array.sort((a: any, b: any) => {
            if (a[field] < b[field]) {
                return -1;
            } else if (a[field] > b[field]) {
                return 1;
            } else {
                return 0;
            }
        });
        return array;
    }
}

@Pipe({
    name: 'safe'
})
export class SafePipe {

    constructor(protected sanitizer: DomSanitizer) { }

    transform(htmlString: string): any {
        return this.sanitizer.bypassSecurityTrustHtml(htmlString);
    }
}

@Pipe({
    name: 'searcharrobj'
})
export class SearchArrObjPipe implements PipeTransform {
    transform(value: any, arr: Array<any>, keyCompared: string, keyGet: string): any {
        for (let i in arr) {
            if (value == arr[i][keyCompared]) {
                return arr[i][keyGet]
            }
        }
    }
}

@Pipe({
    name: 'textValue'
})
export class TextValuePipe implements PipeTransform {
    transform(value: any,textReturn: any): any {
        if (value == null || value == 'null' || value == '') {
            return textReturn? textReturn : ' - '
        } else {
            const str = value.replace(/(?:\r\n|\r|\n)/g, '<br>');
            return str;
        }
    }

    /**
     * else if (moment(value).isValid()) {
            return moment(value).format('MMM D, YYYY ');
        }
     */
}

@Pipe({
    name: 'keysFilter'
})
export class KeysFilterPipe implements PipeTransform {
    transform(value: any): any {
        const arrReturn = [];
        if (value instanceof Array) {
            for (const i in value) {
                if(value[i].key && value[i].value){
                    arrReturn.push(value[i]);
                }else{
                    if(value[i] instanceof Object){
                        let keys = Object.keys(value[i]);
                        arrReturn.push({ key: keys[0], value: keys[0] });
                    }
                }
                
            }
        } else{
            for (const i in value) {
                if (typeof value[i] === 'undefined') {
                    continue;
                }
                arrReturn.push({ key: i, value: value[i] });
            }
        }

        return arrReturn;
    }
}