import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatIconModule, MatMenuModule, MatProgressBarModule, MatToolbarModule ,MatTabsModule,MatCardModule} from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';

import { ToolbarAdminComponent } from 'app/main/toolbar-admin/toolbar-admin.component';
import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';

@NgModule({
    declarations: [
      ToolbarAdminComponent
    ],
    imports     : [
        RouterModule,

        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatProgressBarModule,
        MatToolbarModule,

        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        MatTabsModule,
        MatCardModule
    ],
    exports     : [
      ToolbarAdminComponent
    ]
})
export class ToolbarAdminModule {
}
