import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpService } from '@config/services/http.service';
import { API_URL } from '@config/helpers/const';

import { FuseUtils } from '@fuse/utils';

import * as moment from 'moment';
import { saveAs } from 'file-saver/FileSaver';

@Injectable()
export class MiscService implements Resolve<any>
{
    private data: any = {};
    private path: any;

    constructor(private _http: HttpService) {
    }

    /**
     * The Contacts App Main Resolver
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        let data = route.data;

        return new Promise((resolve, reject) => {

            Promise.all([
                this.getLists(data.lists),
            ]).then(
                ([files]) => {
                    resolve(files);
                },
                error => {
                    reject(error);
                }
                );
        });
    }

    getLists(lists) {
        let url = API_URL.METHOD.MISC + "/" + API_URL.MISC.GETLISTS;
        let data = {
            lists: lists
        }
        return new Promise((resolve, reject) => {
            this._http.post(url, data).subscribe(resolve, error => {
                resolve({
                    status: false,
                    message: error.message
                });
            });
        });
    }

    getListsSubscribe(lists) {
        let url = API_URL.METHOD.MISC + "/" + API_URL.MISC.GETLISTS;
        let data = {
            lists: lists
        }
        return this._http.post(url, data);
    }

    betweenDatesToReplace(stringReplace, datePast, dateCompare) {
        let objReturn = {
            textSuggested: {
                years: '',
                months: '',
                days: '',
                minutes: '',
                seconds: '',
            }, years: 0,
            months: 0,
            days: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
        };
        dateCompare = (!dateCompare) ? moment() : moment(dateCompare);
        datePast = (!datePast) ? moment() : moment(datePast);
        //YEARS
        var years = dateCompare.diff(datePast, 'years');
        objReturn['years'] = years;
        datePast.add(years, 'years');
        //MONTHS
        var months = dateCompare.diff(datePast, 'months');
        objReturn['months'] = months;
        datePast.add(months, 'months');
        //DAY
        var days = dateCompare.diff(datePast, 'days');
        objReturn['days'] = days;
        datePast.add(days, 'days');
        //HOURS
        var hours = dateCompare.diff(datePast, 'hours');
        objReturn['hours'] = hours;
        datePast.add(hours, 'hours');
        //MINUTES
        var minutes = dateCompare.diff(datePast, 'minutes');
        objReturn['minutes'] = minutes;
        datePast.add(minutes, 'minutes');
        //SECONDS
        var seconds = dateCompare.diff(datePast, 'seconds');
        objReturn['seconds'] = seconds;
        datePast.add(seconds, 'seconds');
        //MILLISECOND
        var milliseconds = dateCompare.diff(datePast, 'milliseconds');
        objReturn['milliseconds'] = milliseconds;

        if (years > 0) {
            objReturn.textSuggested.years = years + ((years > 1) ? ' años' : ' año');
        } else {
            objReturn.textSuggested.years = "";
        }
        if (months > 0) {
            objReturn.textSuggested.months = months + ((months > 1) ? ' meses' : ' mes');
        } else {
            objReturn.textSuggested.months = "";
        }
        if (days > 0) {
            objReturn.textSuggested.days = days + ((days > 1) ? ' días' : ' día');
        } else {
            objReturn.textSuggested.days = "";
        }
        if (minutes > 0) {
            objReturn.textSuggested.minutes = minutes + ((minutes > 1) ? ' minutos' : ' minuto');
        } else {
            objReturn.textSuggested.minutes = "";
        }
        if (seconds > 0) {
            objReturn.textSuggested.seconds = seconds + ((seconds > 1) ? ' segundos' : ' segundo');
        } else {
            objReturn.textSuggested.seconds = "";
        }

        objReturn['isToday'] = (years == 0 && months == 0 && days == 0) ? true : false;


        objReturn['justNow'] = (years == 0 && months == 0 && days == 0 && minutes == 0) ? true : false;


        if (stringReplace) {
            stringReplace = stringReplace.replace(/%year%/g, years)
                .replace(/%months%/g, months)
                .replace(/%days%/g, days)
                .replace(/%hours%/g, hours)
                .replace(/%minutes%/g, minutes)
                .replace(/%seconds%/g, seconds)
                .replace(/%milliseconds%/g, milliseconds);
        }

        objReturn['stringReplace'] = stringReplace;

        return objReturn;
    }

    copyObject(obj: Object) {
        return JSON.parse(JSON.stringify(obj));
    }

    getCityByState(idState: number) {
        let url = API_URL.METHOD.MISC + "/" + API_URL.MISC.GETCITYBYSTATE + "/" + idState;
        let data = {}
        return new Promise((resolve, reject) => {
            this._http.get(url, data).subscribe(resolve, error => {
                resolve({
                    status: false,
                    message: error.message
                });
            });
        });
    }

    objToArray(obj) {
        const arrReturn = [];
        for (let i in obj) {
            arrReturn.push(obj[i]);
        }
        return arrReturn;
    }

    updateValue(root, data) {
        const rootCopy = Object.assign({}, root);
        for (const i in data) {
            rootCopy[i] = data[i];
        }
        return rootCopy;
    }

    orderDate(arr, key:string) {
        return arr.sort((a, b) => {
            if (moment(a[key]) < moment(b[key])){            // a comes first
                return 1
            } else if (moment(b[key]) < moment(a[key])) {     // b comes first
                return -1
            } else {                // equal, so order is irrelevant
                return 0            // note: sort is not necessarily stable in JS
            }
        })
    }

    sortArr(arr:Array<any>,keycompared:string,desc:boolean,isString?:boolean){
        return arr.sort((elementA,elementB) => {
            if(isString){
                return (elementA[keycompared] < elementB[keycompared]) ? -1 : (elementA[keycompared] > elementB[keycompared]) ? 1 : 0;
            }else{
                return desc ? elementB[keycompared] - elementA[keycompared] : elementA[keycompared] - elementB[keycompared];
            }
            
        })
    }

    serializeKeys(lists:Object):Array<{key:number,value:string}> {
        const arrReturn = [];
        for (const i in lists) {
            if (typeof lists[i] === 'undefined') {
                continue;
            }
            arrReturn.push({ key: parseInt(i), value: lists[i] });
        }
        return arrReturn;
    }

    mergeJson(rootJson,data){
        for(let i in data){
            rootJson[i] = data[i];
        }
        return rootJson;
    }

    /**
     *  SET AND GET DATA
     */
    setData(key: string, value: any) {
        this.data[key] = value;
    }

    getData(key: string) {
        return this.data[key];
    }

    deleteData(key: string) {
        delete (this.data[key]);
    }

    /**
     *  DONWLOAD FILE
     */

    downloadFile(data: Response, type: string, nameFile: string) {
        var blob = new Blob([data], { type: type });
        saveAs(blob, nameFile);
    }

    /**
     * PAGINATOR
     */
    initPage(limit,index){
        return limit * index;
    }

    limitPage(limit,index){
        return limit * (index + 1);
    }

    /**
     * Hiding btn collapse on responsive screens
     */
    hideBtnCollapseSidebar(){
        //Get sidebar element
        var elementSidebarDOM = document.getElementsByClassName("sidenav");
        //Get button collapse menu
        var btnSidebarDOM = document.getElementsByClassName("sidenav-toggle");
        //If not exists a sidebar element in the DOM, add hidden class to button collapse
        if(elementSidebarDOM.length <= 0){

            var hasClass = btnSidebarDOM[0].className.indexOf("hidden");

            // If doesn't have hidden class
            if(hasClass <= 0){
                btnSidebarDOM[0].className += " hidden";
            }
        }
    }
}