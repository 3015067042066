import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthGuardService } from '@config/auth/auth-guard.service';
import { AuthService } from '@config/auth/auth.service';
import { UserService } from '@config/auth/user.services';
import { HttpService } from '@config/services/http.service';
import { MiscService } from '@config/services/misc.service';
import { ProfileService } from '@config/services/profile.service';
import { FileUploadService } from '@config/services/file_upload.service';
import { childrenService, localStorageService } from '@config/services/localstorage.service';
import { HomeChildrenService } from '@config/services/home-children.services';

import { FusePipesModule } from '@config/pipe/pipes.module';
import { InscriptionService } from '@config/services/inscription.service';
import { SpecialistService } from '@config/services/specialist.service';
import { ScrollService } from '@config/services/scroll.service';

@NgModule({
    imports: [
        FusePipesModule,
    ],
    entryComponents: [],
    providers: [
        AuthGuardService,
        AuthService,
        UserService,
        HttpService,
        MiscService,
        ProfileService,
        FileUploadService,
        childrenService,
        localStorageService,
        HomeChildrenService,
        InscriptionService,
        SpecialistService,
        ScrollService
    ],
    exports: [
        FusePipesModule
    ]
})
export class ConfigModule { }
