
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { UserService } from './user.services';
import { ROLES } from '@config/helpers/const';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router, public _user: UserService) { }
  canActivate(route: ActivatedRouteSnapshot): boolean {

    const idGroup = route.data.idGroup;
    if (!this.auth.isTokenExpired()) {
      const userGroup = this._user.getGroupId();
      if (userGroup) {
        if (userGroup == idGroup) {
          return true;
        } else {
          if (ROLES[userGroup]) {
            let path = "/"+ROLES[userGroup];
            this.router.navigate([path]);
          }else{
            this.router.navigate(['/auth/login']);
          } 
          return false;
        }
      }
      return false;
    }
    this.router.navigate(['/auth/login']);
    return false;
  }
}