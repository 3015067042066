
import { RouterModule, Routes, CanActivate } from '@angular/router';

import { AuthGuardService as AuthGuard } from '../auth/auth-guard.service';

export const appRoutes: Routes = [
  {
    path: 'admin',
    loadChildren: './main/content/admin/admin.module#CedAdminModule',
    canActivate: [AuthGuard],
    data: {
      idGroup: 2
    }
  },
  {
    path: 'spec',
    loadChildren: './main/content/specialist/specialist.module#CedSpecialistsModule',
    canActivate: [AuthGuard],
    data: {
      idGroup: 4
    }
  },
  {
    path: 'doc',
    loadChildren: './main/content/teacher/teacher.module#CedTeacherModule',
    canActivate: [AuthGuard],
    data: {
      idGroup: 5
    }
  },
  {
    path: 'enf',
    loadChildren: './main/content/nurse/nurse.module#CedNurseModule',
    canActivate: [AuthGuard],
    data: {
      idGroup: 3
    }
  },
  {
    path: 'tec',
    loadChildren: './main/content/technical/technical.module#CedTechnicalModule',
    canActivate: [AuthGuard],
    data: {
      idGroup: 7
    }
  },
  {
    path: 'auth',
    loadChildren: './main/content/auth/auth.module#CedAuthModule'
  },
  {
    path: '',
    loadChildren: './main/content/parents/parents.module#CedParentsModule',
    canActivate: [AuthGuard],
    data: {
      idGroup: 6
    }
  },

  { path: '**', redirectTo: '' }
];