import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MatSidenavModule, MatIconModule} from '@angular/material';

import {FuseSharedModule} from '@fuse/shared.module';
import {FuseNavigationModule, FuseSearchBarModule, FuseShortcutsModule, FuseSidebarModule, FuseThemeOptionsModule} from '@fuse/components';

import {CedContentModule} from './content/content.module';
import {FuseFooterModule} from './footer/footer.module';
import {FuseNavbarModule} from './navbar/navbar.module';
import {FuseQuickPanelModule} from './quick-panel/quick-panel.module';
import {FuseToolbarModule} from './toolbar/toolbar.module';
import {ToolbarTabsModule} from './toolbar-tabs/toolbar-tabs.module';
import {ToolbarAdminModule} from './toolbar-admin/toolbar-admin.module';
import {CedMainComponent} from './main.component';


@NgModule({
  declarations: [
    CedMainComponent,
  ],
  imports: [
    RouterModule,

    MatSidenavModule,

    MatIconModule,

    FuseSharedModule,

    FuseThemeOptionsModule,
    FuseNavigationModule,
    FuseSearchBarModule,
    FuseShortcutsModule,
    FuseSidebarModule,

    CedContentModule,
    FuseFooterModule,
    FuseNavbarModule,
    FuseQuickPanelModule,
    FuseToolbarModule,
    ToolbarTabsModule,
    ToolbarAdminModule
  ],
  exports: [
    CedMainComponent
  ]
})
export class CedMainModule {
}
