import { Component, Input } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

//@CONFIG
import { UserService } from '@config/auth/user.services';
import { AuthService } from '@config/auth/auth.service';
import { childrenService, localStorageService } from '@config/services/localstorage.service';
import { STAGES, IDSTAGES, URL_MANUALS } from '@config/helpers/const';
import { HttpService } from '@config/services/http.service';
import { ToolbarTabsService } from '@config/services/toolbar-tabs.service';

@Component({
    selector: 'toolbar-tabs',
    templateUrl: './toolbar-tabs.component.html',
    styleUrls: ['./toolbar-tabs.component.scss']
})

export class ToolbarTabsComponentComponent {
    userStatusOptions: any[];
    languages: any;
    selectedLanguage: any;
    showLoadingBar: boolean;
    horizontalNav: boolean;
    noNav: boolean;
    navigation: any;
    user: any;
    children: any = [];
    version: any = '';
    manual:any = null;
    manualURL:string = "";

    showLeft = false;
    config: any = {
        left: true,
        rigth: true,
    };

    // Cambiar clase componente toolbar
    @Input() classToolbar: any;

    constructor(
        private router: Router,
        private fuseConfig: FuseConfigService,
        private sidebarService: FuseSidebarService,
        private translate: TranslateService,
        private userService: UserService,
        private _auth: AuthService,
        private _children: childrenService,
        private _localStorage: localStorageService,
        private _http: HttpService,
        private _configTabs: ToolbarTabsService
    ) {
        this.version = this._http.getVersion();
        this.user = this.userService.getProfile();

        

        this.manual = this.userService.getManual();
        this.manualURL = URL_MANUALS;

        this.navigation = [];
        this._children.childenOb.subscribe(data => {
            this.children = data;
        });

        this._configTabs.configOb.subscribe(data => {
            console.log(data,'tabs component');
            //setTimeout(() => this.config = data, 0);
        });

        this.children = JSON.parse(this._localStorage.getItem('children'));

        router.events.subscribe(
            (event) => {
                if ( event instanceof NavigationStart ) {
                    this.showLoadingBar = true;
                }
                if ( event instanceof NavigationEnd ) {
                    this.showLoadingBar = false;
                }
            });

        this.showHorizontal(this.userService.getGroupId());
    }

    goDetail(children) {
        if (!children) {
            return this.router.navigate(['']);
        }
 
        if (children.status_registration.id == IDSTAGES.REGISTERED) {
            this.router.navigate(['diary', children.children_id]);
            // this.router.navigate(['info_children', children.id]);
        } else {
            this.router.navigate(['info_children', children.id]);
        }
    }

    showHorizontal(group) {
        if (group !== 6) {
            this.showLeft = true;
        }
    }

    logout() {
        this.router.navigate(['/auth/login']);
        this._auth.logout();
    }
}
