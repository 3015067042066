import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class ProfileService implements Resolve<any> {
  timeline: any;
  about: any;
  photosVideos: any;

  timelineOnChanged: BehaviorSubject<any> = new BehaviorSubject({});
  aboutOnChanged: BehaviorSubject<any> = new BehaviorSubject({});
  photosVideosOnChanged: BehaviorSubject<any> = new BehaviorSubject({});

  constructor(private http: HttpClient) {
  }

  /**
   * Resolve
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.getTimeline()
      ]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }

  /**
   * Get timeline
   */
  getTimeline() {
    return new Promise((resolve, reject) => {

      resolve({
        'activities': [
          {
            'user': {
              'name': 'Alice Freeman',
              'avatar': 'assets/images/avatars/alice.jpg'
            },
            'message': 'started following you.',
            'time': '13 mins. ago'
          },
          {
            'user': {
              'name': 'Andrew Green',
              'avatar': 'assets/images/avatars/andrew.jpg'
            },
            'message': 'sent you a message.',
            'time': 'June 10,2015'
          },
          {
            'user': {
              'name': 'Garry Newman',
              'avatar': 'assets/images/avatars/garry.jpg'
            },
            'message': 'shared a public post with your group.',
            'time': 'June 9,2015'
          },
          {
            'user': {
              'name': 'Carl Henderson',
              'avatar': 'assets/images/avatars/carl.jpg'
            },
            'message': 'wants to play Fallout Shelter with you.',
            'time': 'June 8,2015'
          },
          {
            'user': {
              'name': 'Jane Dean',
              'avatar': 'assets/images/avatars/jane.jpg'
            },
            'message': 'started following you.',
            'time': 'June 7,2015'
          },
          {
            'user': {
              'name': 'Juan Carpenter',
              'avatar': 'assets/images/avatars/james.jpg'
            },
            'message': 'sent you a message.',
            'time': 'June 6,2015'
          },
          {
            'user': {
              'name': 'Judith Burton',
              'avatar': 'assets/images/avatars/joyce.jpg'
            },
            'message': 'shared a photo with you.',
            'time': 'June 5,2015'
          },
          {
            'user': {
              'name': 'Vincent Munoz',
              'avatar': 'assets/images/avatars/vincent.jpg'
            },
            'message': 'shared a photo with you.',
            'time': 'June 4,2015'
          }
        ],
        'posts': [
          {
            'user': {
              'name': 'Garry Newman',
              'avatar': 'assets/images/avatars/garry.jpg'
            },
            'message': 'Remember the place we were talking about the other night? Found it!',
            'time': '32 minutes ago',
            'type': 'post',
            'like': 5,
            'share': 21,
            'media': {
              'type': 'image',
              'preview': 'assets/images/etc/early-sunrise.jpg'
            },
            'comments': [
              {
                'user': {
                  'name': 'Alice Freeman',
                  'avatar': 'assets/images/avatars/alice.jpg'
                },
                'time': 'June 10, 2015',
                'message': 'That’s a wonderful place. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce et eleifend ligula. Fusce posuere in sapien ac facilisis. Etiam sit amet justo non felis ornare feugiat.'
              }
            ]
          },
          {
            'user': {
              'name': 'Carl Henderson',
              'avatar': 'assets/images/avatars/carl.jpg'
            },
            'message': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce et eleifend ligula. Fusce posuere in sapien ac facilisis. Etiam sit amet justo non felis ornare feugiat. Aenean lorem ex, ultrices sit amet ligula sed...',
            'time': 'June 10, 2015',
            'type': 'something',
            'like': 4,
            'share': 1
          }
        ]
      });

    });
  }

}
