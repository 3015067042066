import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';

@Injectable()
export class ScrollService {

    private _scroll = new Subject();
    scrollOb = this._scroll.asObservable();

    constructor() { }

    setScroll(data:any){
        
        var porcent = (data.scrollHeight - data.heigthContent) * 0.10;
        if(((data.scrollHeight - data.heigthContent) - porcent) < parseInt(data.position, 10)){
            data.bottomBool = true;
        }else{
            data.bottomBool = false;
        }

        this._scroll.next(data);
    }

}