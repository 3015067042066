import { Pipe, PipeTransform } from '@angular/core';
import { FuseUtils } from '@fuse/utils';
import * as moment from 'moment';

@Pipe({ name: 'format' })
export class MomentPipe implements PipeTransform {
    transform(date: any, format: string, utc: string, defautl: string): any {
        if (!moment(date).isValid()) {
            return defautl;
        }
        if (utc) {
            return moment(date).format(format);
        } else {
            return moment.utc(date).format(format);
        }

    }
}

@Pipe({ name: 'datetoage' })
export class DateToAgePipe implements PipeTransform {
    transform(datePast: any): any {
        datePast = moment(datePast);
        if (!datePast.isValid()) {
            return null;
        }
        let today = moment();
        let months = today.diff(datePast, 'months');
        if (months <= 0) {
            let days = today.diff(datePast, 'days');
            if (days <= 0) {
                return 'Recien nacido';
            } else if (days == 1) {
                return '1 Día';
            }
            return days + ' Días';
        } else if (months == 1) {
            return '1 Mes';
        }
        return months + ' Meses';
    }
}
