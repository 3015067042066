import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { API_URL,TOKEN_NAME,KEYAUTHHEADER,GROUPS } from '@config/helpers/const';

@Injectable({
  providedIn: 'root'
})
export class SpecialistService {

	constructor() { 
    }

    getPermission(user){
        let permissions: any = {
            show:{
                alert_measure:true,
                new_registration:true
            }
        }
        switch(user.kind){
            case 3:
            //fonoaudiologo
            
            break;
            case 4:
            //fisioterapia
            break;
            case 5:
            //psicologia
            break;
            case 7:
            //ARTE
            permissions.show.alert_measure = false;
            permissions.show.new_registration = false;
            break;
            case 8:
            //MUSIC
            permissions.show.alert_measure = false;
            permissions.show.new_registration = false;
            break;
            case 9:
            //TEATHER
            permissions.show.alert_measure = false;
            permissions.show.new_registration = false;
            break;
        }

        return permissions;
    }
   
}