import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class HomeChildrenService {

    private chindreData: any;
    private childrenData: any;

    private _children = new Subject();
    childenOb = this._children.asObservable();

    private _childrenData = new Subject();
    childrenDataOb = this._childrenData.asObservable();

    constructor() { }

    setChildrenData(data: any) {
        this.childrenData = data;
        this._childrenData.next(data);
    }

    setChildren(data: any) {
        this.chindreData = data;
        this._children.next(data);
    }

    getChildren() {
        return this.chindreData;
    }

    getChildrenData() {
        return this.childrenData;
    }

}