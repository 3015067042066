import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import { CedContentComponent } from './content.component';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ToolbarTabsService } from '@config/services/toolbar-tabs.service';
import { NgxChartsModule } from '@swimlane/ngx-charts';

@NgModule({
    declarations: [
        CedContentComponent
    ],
    imports     : [
        RouterModule,
        BrowserAnimationsModule, 
        FuseSharedModule,
        NgxChartsModule
    ],
    exports: [
        CedContentComponent
    ],
    providers: [ToolbarTabsService]
})
export class CedContentModule
{
}
