import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { DOCUMENT } from '@angular/platform-browser';
import { Observable } from 'rxjs/Observable';
import { API_URL, TOKEN_NAME, KEYAUTHHEADER, VERSION_DEV, VERSION_TEST, VERSION_PROD } from '@config/helpers/const';
import { MiscService } from '@config/services/misc.service';
import { Location } from '@angular/common';

@Injectable()
export class HttpService {

	private token: any;
	public path: string;

	constructor(private http: HttpClient, @Inject(DOCUMENT) private _document) {
		this.path = this.gerUrlBaseAndPath();
	}

	private validateheader():any {
		let head = {
			'Content-Type' : 'application/json'
		}
		let token = this.getToken();
		if (token) {
			head[KEYAUTHHEADER] = token;
		}
		return head;
	}

	public setToken(token) {
		this.token = token;
		this.validateheader();
	}

	public getToken(): string {
		let token = localStorage.getItem(TOKEN_NAME) || null;
		return token;
	}

	/**
	 * @var domain check current domain
	 * @const API_URL.BASE_URL_PRUEBAS url server (ej)
	 * @returns URL SERVER
	 */
	public getUrlBase() {
		const host = this._document.location.host;
		const hostParts = host.split('.');
		const domain = hostParts[0];
		let urlReturn: string;
		switch (domain) {
			case 'pruebas-ced':
				urlReturn = `${API_URL.BASE_URL_PRUEBAS}`;
				break;
			case 'desarrollo-ced':
				urlReturn = `${API_URL.BASE_URL_DEV}`;
				break;
			case 'ced':
				urlReturn = `${API_URL.BASE_URL_PROD}`;
				break;
			default:
				urlReturn = `${API_URL.BASE_URL_LOCAL}`;
				break;
		}
		return urlReturn;
	}

	/**
	 * @var domain check current domain
	 * @const VERSION_TEST Version assigned
	 * @returns Version application
	 */
	public getVersion() {
		const host = this._document.location.host;
		const hostParts = host.split('.');
		const domain = hostParts[0];
		let urlReturn: string;
		switch (domain) {
			case 'pruebas-ced':
				urlReturn = VERSION_TEST;
				break;
			case 'desarrollo-ced':
				urlReturn = VERSION_DEV;
				break;
			case 'ced':
				urlReturn = VERSION_PROD;
				break;
			default:
				urlReturn = VERSION_DEV;
				break;
		}
		return urlReturn;
	}

	/**
	 * @var domain check current domain
	 * @const API_URL.PATH Refers to version api. Ej: 'api/v1/'
	 * @returns URL API
	 */
	public gerUrlBaseAndPath() {
		const host = this._document.location.host;
		const hostParts = host.split('.');
		const domain = hostParts[0];
		let urlReturn: string;
		switch (domain) {
			case 'pruebas-ced':
				urlReturn = `${API_URL.BASE_URL_PRUEBAS}/${API_URL.PATH}`;
				break;
			case 'desarrollo-ced':
				urlReturn = `${API_URL.BASE_URL_DEV}/${API_URL.PATH}`;
				break;
			case 'ced':
				urlReturn = `${API_URL.BASE_URL_PROD}/${API_URL.PATH}`;
				break;
			default:
				urlReturn = `${API_URL.BASE_URL_LOCAL}/${API_URL.PATH}`;
				break;
		}
		return urlReturn;
	}

	public request(
		method: 'get' | 'head' | 'post' | 'put' | 'delete' | 'options' | 'patch' | 'upload',
		url: string,
		params: any,
		options: any = {}
	): Observable<any> {
		let base;
		const head = this.validateheader();
		const URI = (options.url ? url : this.path + url);
		switch (method) {
			case 'get':
				base = this.http.get(URI, {
					params: params,
					headers: head
				});
				break;
			case 'head':
				base = this.http.head(URI, {
					params: params,
					headers: head
				});
				break;
			case 'post':
				base = this.http.post(URI, params, {
					headers: head
				});
				break;
			case 'put':
				base = this.http.put(URI, params, {
					headers: head
				});
				break;
			case 'delete':
				base = this.http.delete(URI, {
					params: params,
					headers: head
				});
				break;
			case 'options':
				base = this.http.options(URI, {
					params: params,
					headers: head
				});
				break;
			case 'patch':
				base = this.http.patch(URI, params, {
					headers: head
				});
				break;
			default:
				base = this.http.get(URI, {
					params: params,
					headers: head
				});
		}

		return base;
	}

	public get(url: string, params: any,options:any = {}): Observable<any> {
		return this.request('get', url, params,options);
	}

	public post(url: string, params: any): Observable<any> {
		return this.request('post', url, params);
	}

	public put(url: string, params: any): Observable<any> {
		return this.request('put', url, params);
	}

	public patch(url: string, params: any): Observable<any> {
		return this.request('patch', url, params);
	}

	public delete(url: string, params: any): Observable<any> {
		return this.request('delete', url, params);
	}

	public downloadFile(url: string, params: any): Observable<any> {
		const URI = this.path + url;
		const head = this.validateheader();
		return this.http.get(URI, {
			params: params,
			headers: head,
			responseType: 'arraybuffer'
		});
	}

}