import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType, HttpRequest, HttpErrorResponse, HttpEvent } from '@angular/common/http';


import { Observable} from 'rxjs/Observable';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { of } from  'rxjs/observable/of';
import { catchError, map, tap } from  'rxjs/operators';

import { API_URL,TOKEN_NAME,KEYAUTHHEADER } from '@config/helpers/const';
import { HttpService } from '@config/services/http.service';

/* Naming NOTE
  The API's file field is `fileItem` thus, we name it the same below
  it's like saying <input type='file' name='fileItem' /> 
  on a standard file field
*/


@Injectable()
export class FileUploadService {

    private path:string;
    private httpOptions = {
      headers: new HttpHeaders()
    };
    constructor(private http: HttpClient,private _http:HttpService){
      this.path = this._http.gerUrlBaseAndPath();
      
     }

     private validateHeaders(){
      let token = this._http.getToken();
      let header = {};
          if(token){
        header[KEYAUTHHEADER] = token;
      }
      this.httpOptions.headers = new HttpHeaders(header);
      }

    fileUpload(route:string, fileItem:File, extraData?:object, nameAppend?:string):Observable<HttpEvent<any>>{
      this.validateHeaders();
      
      let apiCreateEndpoint = this.path+route;
      const formData: FormData = new FormData();
      
      formData.append((nameAppend ? nameAppend :'file'), fileItem, fileItem.name);
      if (extraData) {
        for(let key in extraData){
            // iterate and set other form data
          formData.append(key, extraData[key])
        }
      }

      const req = new HttpRequest('POST', apiCreateEndpoint, formData, {
        headers:this.httpOptions.headers,
        reportProgress: true, // for progress data
      });
      return this.http.request(req)
    }

  optionalFileUpload(route:string,fileItem?:File, extraData?:object):any{
      let apiCreateEndpoint = this.path+route;
      const formData: FormData = new FormData(); //?
       let fileName;
      if (extraData) {
        for(let key in extraData){
            // iterate and set other form data
            if (key == 'fileName'){
              fileName = extraData[key]
            }
          formData.append(key, extraData[key])
        }
      }

      if (fileItem){
        if (!fileName){
           fileName = fileItem.name
        }
        formData.append('image', fileItem, fileName);
      }
      const req = new HttpRequest('POST', apiCreateEndpoint, formData, {
        reportProgress: true // for progress data
      });
      return this.http.request(req)
  }

}