import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';

@Injectable()
export class localStorageService{
    saveItem(key:string,data:any){
        localStorage.setItem(key,data);
    }

    getItem(key:string){
        return localStorage.getItem(key);
    }

    deleteItem(key:string){
        return localStorage.removeItem(key);
    }
}

@Injectable()
export class childrenService {

    private _children = new Subject();
    childenOb = this._children.asObservable();

    constructor(private _localStorage:localStorageService) { }

    setChildren(data:any){
        this._children.next(data);
    }

    updateChildren(children){
        const childrens = JSON.parse(this._localStorage.getItem('children'));
        for (const i in childrens) {
            if (childrens[i].id === children.id) {
                childrens[i] = children;
                break;
            }
        }
        this._localStorage.saveItem('children', JSON.stringify(childrens));
        this.setChildren(childrens);
    }

}

