import { Injectable } from '@angular/core';

import { TOKEN_NAME, USER_DATA, MENU,LAYOUT,LISTS_KEY, CHILDREN } from '@config/helpers/const';

@Injectable()
export class UserService {

    constructor() { }

    removeUser() {
        localStorage.removeItem(USER_DATA);
        localStorage.removeItem(MENU);
        localStorage.removeItem(LAYOUT);
        localStorage.removeItem(CHILDREN);
    }

    setUser(data: any) {
        localStorage.setItem(USER_DATA, JSON.stringify(data));
    }

    setLists(data: any){
        localStorage.setItem(LISTS_KEY, JSON.stringify(data));
    }

    setMenu(data: any) {
        /*let menu = [];
        let group = {
            'id': 'Matricula',
            'title': 'Matricula',
            'translate': 'Gestion de matriculas',
            'type': 'group',
            'icon': 'apps',
            'children': [{
                id:1,
                title:'Nuevos Ingresos',
                type:'item',
                url:'/admin/new_inscriptions'
            },{
                id:3,
                title:'Niños matriculados',
                type:'item',
                url:'/admin/childrens_active'
            },{
                id:4,
                title:'Niños retirados',
                type:'item',
                url:'/admin/childrens_inactivo'
            },{
                id:5,
                title:'Docentes',
                type:'item',
                url:'/admin/teachers'
            },{
                id:6,
                title:'Periodos',
                type:'item',
                url:'/admin/period'
            },{
                id:2,
                title:'Autorizaciones',
                type:'item',
                url:'/admin/authorizations'
            }
        ]
        };*/
        /*if (data.length > 0) {
            for (let i in data) {
                group.children.push({
                    'id': i,
                    'title': data[i].label,
                    'type': 'item',
                    'url': data[i].url
                });
            }
            menu.push(group);
        }*/
        //menu.push(group);
        localStorage.setItem(MENU, JSON.stringify(data));
    }

    setLayout(layout:any){
        localStorage.setItem(LAYOUT,JSON.stringify(layout));
    }

    getMenu() {
        return JSON.parse(localStorage.getItem(MENU));
    }

    getUser() {
        return JSON.parse(localStorage.getItem(USER_DATA));
    }

    getLayout(){
        return JSON.parse(localStorage.getItem(LAYOUT));
    }

    getLists(){
        return JSON.parse(localStorage.getItem(LISTS_KEY));
    }


    getProfile(user?: any) {
        if (!user) { user = this.getUser(); }
        if (!user) { return false; }
        return user.profile;
    }

    getIdUser(user?: any) {
        if (!user) { user = this.getUser(); }
        if (!user) { return false; }
        return user.id;
    }

    getGroup(user?: any) {
        if (!user) { user = this.getUser(); }
        if (!user) { return false; }
        return user.group;
    }

    getGroupId(user?: any) {
        if (!user) { user = this.getUser(); }
        if (!user) { return false; }
        return user.group_id;
    }

    getManual(user?: any) {
        if (!user) { user = this.getUser(); }
        if (!user) { return false; }
        return user.manual;
    }

    

}
